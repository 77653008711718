// src/components/HomePage.js
import React, { useState } from 'react';
import './HomePage.css';
import Modal from './Modal';

const HomePage = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = {
      email: formData.get('email'),
      mobile: formData.get('mobile'),
      time: formData.get('time'),
      category: formData.get('category'),
    };
    console.log(data);
    // You can now send this data to your server or handle it as needed
    handleCloseModal();
  }

  return (
    <div className="homepage">
      <h1>We Manage Your All In All IT Solutions</h1>
      <p>
        Wide range of innovative IT solutions for Web Development, UI/UX Design, Freelancer Services, and IT Support.
      </p>
      <button onClick={handleOpenModal}>Request Demo</button>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleSubmit} />
    </div>
  );
}

export default HomePage;
