import React from 'react';
import './ContactUs.css';
import ContactImage from '../assets/images/Contact-Us.webp'; // Replace with your actual image path

const ContactUs = () => {
  return (
    <section id="contact">
      <div className="contact-header">
        <h2>Contact Us</h2>
      </div>
      <div className="contact-content">
        <div className="contact-image">
          <img src={ContactImage} alt="Contact Us" />
        </div>
        <div className="contact-form">
          <p>
            We’d love to talk to you about these and any other questions you may have about any software around the globe. There are every alternative available to help improve your productivity in these tough times while helping to lower your operational costs.
          </p>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" />
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile:</label>
              <input type="tel" id="mobile" name="mobile" />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message"></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
