import React from 'react';
import './AboutUs.css';
import NewImage from '../assets/images/img.jpg'; // Replace with your actual image path

const AboutUs = () => {
  return (
    <section id="about">
      <div className="about-container">
        <div className="about-image">
          <img src={NewImage} alt="IT Company" />
        </div>
        <div className="about-text">
          <h2>Why Soft-LI Global Technologies?</h2>
          <p>
            <strong>Soft-LI Global Technologies</strong> is your trusted partner in delivering innovative IT solutions, specializing in Web Development, IT Support, UI/UX Design, and Freelance Services. We are committed to empowering businesses by providing modern, responsive websites, reliable IT infrastructure, and intuitive user interfaces that enhance user engagement. With a team of experienced professionals, we offer personalized, cutting-edge solutions that drive growth and efficiency, ensuring that your business thrives in today’s digital landscape. At Soft-LI Global Technologies, we are dedicated to exceeding your expectations and helping you achieve your full potential through technology.
          </p>
        </div>
      </div>
      <div className="about-values">
        <div className="value-item">
          <h3>Our Values</h3>
          <p>At Soft-LI Global Technologies, the values we stand by have shaped our culture, driven our success, and guided us in making a positive impact in everything we do.</p>
        </div>
        <div className="value-item">
          <h3>Our Vision</h3>
          <p>To become a prime performer in delivering quality IT solutions across the globe, setting standards in innovation and customer satisfaction.</p>
        </div>
        <div className="value-item">
          <h3>Our Mission</h3>
          <p>To provide continued customer satisfaction by delivering top-quality IT solutions that help businesses achieve their goals through the power of technology.</p>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
