import React from "react";
import "./Header.css";
import companyLogo from "../assets/images/logo.jpeg";

const Header = () => {
  return (
    <header>
      {/* Top bar with logo, company name, and contact info */}
      <div className="top-bar">
        {/* Left side: Company Logo */}
        <div className="company-logo">
          <img src={companyLogo} alt="Company Logo" />
        </div>

        {/* Middle: Company Name */}
        <div className="company-name">
          <h1>SOFT-LI GLOBAL TECHNOLOGIES</h1>
        </div>

        {/* Right side: Contact Info */}
        <div className="contact-info">
          <p>Email: Teams@Soft-LI.com | Phone: 9310933579</p>
          {/* Social Icons - Placed below contact info */}
          <div className="social-icons">
            <a
              href="https://facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </div>
        </div>
      </div>

      {/* Navigation Menu below the company name */}
      <nav>
        <ul className="nav-menu">
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About Us</a>
          </li>
          <li>
            <a href="#products">Products</a>
          </li>
          <li>
            <a href="#contact">Contact Us</a>
          </li>
          <li>
            <a href="#quote">Request a Demo</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
