import React from 'react';
import './App.css';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import HomePage from './components/HomePage.js';
import AboutUs from './components/AboutUs.js';
import Products from './components/Products.js';
import ContactUs from './components/ContactUs.js';

function App() {
  return (
    <div className="App">
      <Header />
      <HomePage />
      <AboutUs />
      <Products />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
