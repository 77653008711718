import React from 'react';
import './Products.css';

// Importing images
import WebDevelopmentImage from '../assets/images/webDevelopment.jpeg';
import UiUxImage from '../assets/images/uiux.jpeg';
import FreelanceImage from '../assets/images/freelance.jpeg';
import ITSupportImage from '../assets/images/it.jpeg';

const Products = () => {
  return (
    <section id="products">
      <h2>Products</h2>
      <div className="product-grid">
        <div className="product-item">
          <img src={WebDevelopmentImage} alt="Website Development" />
          <h3>Website Development</h3>
          <p>Professional website development services to build modern, responsive websites.</p>
        </div>
        <div className="product-item">
          <img src={UiUxImage} alt="UI/UX" />
          <h3>UI/UX</h3>
          <p>Designing intuitive and user-friendly interfaces to enhance user experience.</p>
        </div>
        <div className="product-item">
          <img src={FreelanceImage} alt="Freelancer" />
          <h3>Freelancer</h3>
          <p>Offering freelancer services for various development and design needs.</p>
        </div>
        <div className="product-item">
          <img src={ITSupportImage} alt="IT Company" />
          <h3>IT Company</h3>
          <p>Innovative solutions provided by our partner company, IT Company.</p>
        </div>
      </div>
    </section>
  );
}

export default Products;
