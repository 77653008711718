import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, onSubmit }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Request Demo</h2>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="mobile">Mobile:</label>
            <input type="tel" id="mobile" name="mobile" required />
          </div>
          <div className="form-group">
            <label htmlFor="time">Preferred Time:</label>
            <input type="time" id="time" name="time" required />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category:</label>
            <select id="category" name="category" required>
              <option value="web-development">Web Development</option>
              <option value="ui-ux">UI/UX Design</option>
              <option value="freelance">Freelancer Services</option>
              <option value="it-support">IT Support</option>
            </select>
          </div>
          <div className="modal-buttons">
            <button type="submit" className="submit-btn">Submit</button>
            <button type="button" className="close-btn" onClick={onClose}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Modal;
